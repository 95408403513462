import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"


const IndexPage = ({data}) => {
  return (
    <Layout>
      <Seo title="Home" />
      <BgImage
        image={getImage(data.placeholderImage)}
        className="masthead"
      >
        <div className="d-flex justify-content-center h-100 align-items-end justify-content-center">


        </div>

      </BgImage>
      <div>
        <div className="masthead-info">
          <h1>The History of Savannah's Civil Rights Movement</h1>
        </div>

        {/* <Container>
              <div className="brick-red-bg mb-3 mb-md-5">
                <Row className="d-md-flex align-items-center align-self-center">
                  <Col lg={6} className="  d-md-flex align-items-center align-self-center h-100">
                    <div className="p-lg-5 p-3">
                    <h4>Free & Open to the Public 1PM – 3PM</h4>
                    <h3>The Ralph Mark Gilbert Civil Rights Museum celebrates Juneteenth, 2023 on Sunday June 18th
        </h3>
        
        
                   
                    <p>
                    Join us on Father's Day, as the museum honors Dr. Ralph Mark Gilbert, "The Father of the Civil Rights Movement in Savannah," and Mrs. Eloria S. Gilbert who worked, in the movement, as a field secretary of the National Association for the Advancement of Colored People (NAACP).
                    </p>
                    </div>
                  </Col>
                  <Col lg={6} className="text-center ">
                    <p className="px-3">
                      <StaticImage
                        src="../images/juneteenth-2023.jpg"
                        quality={95}
                        width={450}
                        formats={["auto", "webp", "avif"]}
                        alt="Juneteenth Banner"
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            </Container> */}
        <Container>
          <div className="brick-red-bg mb-3 mb-md-5">
            <Row>
              <Col lg={7} className="p-4 p-md-5 d-md-flex align-items-evenly align-self-center h-100">
                <Container>
                  <h3>Hear the story of Savannah’s longest serving NAACP president Westley Wallace Law</h3>
                  <p>
                    W. W. Law was president of the local chapter of the National Association for the Advancement of Colored People (NAACP) and founder of the Civil Rights Museum and the Black Heritage Festival. The Museum is named for his mentor, Reverend Dr. Ralph Mark Gilbert, the 13th pastor of the First African Baptist Church and the “Modern father of Savannah’s Civil Rights Movement.”
                  </p>
                  <p>
                    The Ralph Mark Gilbert Civil Rights Museum is dedicated to the history of Savannah’s Civil Rights Movement and the story of the “freedom fighters” of the local chapter of the NAACP.
                  </p>
                </Container>
              </Col>
              <Col lg={4} className="text-center">
                <StaticImage
                  src="../images/law-in-church.jpg"
                  quality={95}
                  width={450}
                  formats={["auto", "webp", "avif"]}
                  alt="Museum Exterior" />
              </Col>
            </Row>
          </div>
        </Container>

        <Container className="standout">
          <Row className="g-0 pb-3 pb-md-3 pb-lg-0">
            <Col lg={6} className="px-3 px-md-5 py-3 d-flex justify-content-center flex-column text-left">
              <p className="lead singleCol my-3"><strong>Come</strong> visit the Ralph Mark Gilbert Civil Rights Museum. Walk through three floors of history. Read about Ralph Mark Gilbert, the 13th pastor of Savannah’s First African Baptist Church. Remembered as the “father” of the 20th century Civil Rights Movement in Savannah, who reignited the local chapter of the National Association of the Advancement of Colored People (NAACP) in the 1940s. Gilbert mentored Westley Wallace Law, who took over the leadership of the NAACP in 1950 and led the protest movement of the 1960s.</p>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../images/opening-image-judge.jpg"
                quality={75}
                formats={["auto", "webp", "avif"]}
                alt="Museum entrance"
                aspectRatio={1.2}
                className="card-img-top" />
            </Col>
          </Row>

          <Row className="g-0">
            <Col lg={6}>
              <StaticImage
                src="../images/lunch-counter.jpg"
                quality={75}
                formats={["auto", "webp", "avif"]}
                alt="Lunch Counter"
                aspectRatio={1.5}
                className="card-img-top" />
            </Col>
            <Col lg={{span: 6, order: 'last'}} xs={{ order: 'first' }}  className="px-3 px-md-5 d-lg-flex my-4 mb-4 justify-content-center flex-column text-left">
              <p className="lead"><strong>See</strong> the 1960s lunch counter like the ones the NAACP staged the sit-ins that resulted in an economic boycott of white businesses on Broughton Street that helped desegregate the city businesses. </p>
            </Col>
            
          </Row>

          <Row className="g-0 pb-3 pb-md-3 pb-lg-0">
            <Col lg={6} className="px-3 px-md-5 my-4 mb-3 d-flex justify-content-center flex-column text-left">
              <p className="lead"><strong>Learn</strong> about the NAACP’s fight for Voting Rights led by Hosea Williams and Benjamin Van Clark of the Chatham County Crusade for Voters (CCCV). In 1963, there were CCCV lunch time protests in downtown city squares. There were night marches with police on standby and protest leaders were arrested.</p>
            </Col>
            <Col lg={6}>
            <StaticImage
              src="../images/naacp-3.jpg"
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt="NAACP"
              aspectRatio={1.5} /> <br />
            <small>Benjamin Van Clark, RMG Frederick C. Baldwin Collection</small>
            </Col>
          </Row>
          
        </Container>

        <Container className="content-box singleCol">


          
          <p>
            
          </p>

          

          <p>
            
          </p>
          
          <p>
            
          </p>




        </Container>


      </div>

      
      <div className="neutral-gray-bg p-3 content-box mt-5">



        <Container className="d-md-flex box-shadow-02 my-3 my-md-5">
          <div className="w-100 mx-md-4">


            <h2 className="display-5">We Are Open</h2>
            
            <p className="lead">
              Thursday – Saturday<br />
              10am - 5pm (last tour at 3:30pm)</p>

            {/* <h3>Limited Group Tours Available</h3>
            <p>Tuesday & Wednesday to reserved groups of 20 or more. </p> */}

            <h3 className="display-6">Admission <small>*</small></h3>

            <p className="lead">
              <strong>Individuals:</strong> Adults $14 | Seniors $8 | Students $7
              <br />
              <strong>Groups:</strong> Adults $10 | Seniors $7 | Students $6 | Educators w/ students $6
            </p>
            <p>
              * Individuals - Debit / Credit card only<br />
              * Groups - Debit / Credit / Business Checks
            </p>
          </div>
          <div className="ratio ratio-16x9">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.8944323875594!2d-81.10232294916513!3d32.07210392680649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb9e422d57c741%3A0x14f1d47e882ad43a!2sRalph%20Mark%20Gilbert%20Civil%20Rights%20Museum!5e0!3m2!1sen!2sus!4v1660317627584!5m2!1sen!2sus" title="map"></iframe>
          </div>
        </Container>
      </div>

      
      
      <Container className="content-box">
        <h2 className="display-5">Savannah's Civil Rights Heroes</h2>
        <Row className="m-2 m-lg-0 g-4">
          {data.civilRightsHeroes.edges.map(({ node }) => (
            <Col lg={4} md={6} key={node.id}>
              <div className="card box-shadow-02">
                <GatsbyImage image={getImage(node.frontmatter.image01)} className="card-img-top" alt={node.frontmatter.image01alt} />
                <div className="p-3">
                  <h3 className="card-title">{node.frontmatter.title}</h3>
                  <p className="card-text">{node.frontmatter.shortDescription}</p>
                  <div className="text-end">
                    <a href={node.fields.slug} className="btn brick-red-bg">Read More
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

      </Container>

      <Container className="content-box">

        <Row className="m-2 m-lg-0 g-4">
          <h2 className="display-5">Take a Closer Look at Savannah’s Civil Rights Movement</h2>
          <Col lg={4}>
            <div className="card box-shadow-02">
              <StaticImage
                src="../images/garrison-sign.jpg"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Garrison signage"
                aspectRatio={1.5}
                className="card-img-top" />
              <div className="card-body">
                <h3 className="card-title">School Activities Collection</h3>
                <p className="card-text">Engage in the history of Civil Rights.</p>
                <div className="text-end">
                  <Link to="/school-activities/" className="btn brick-red-bg">Read More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg></Link>
                </div>
              </div>
            </div>
          </Col>

          {/* <Col lg={4}>
              <div className="card box-shadow-02">
                <StaticImage
                  src="../images/pjamerson.jpg"
                  width={500}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  aspectRatio={1.5}
                  className="card-img-top"
                />
                <div className="card-body">
                  <h3 className="card-title">Education for All</h3>
                  <p className="card-text">Learn about the history of integration of Savannah's schools and share your education story.</p>
                  <div className="text-end">
                    <a href="https://apple.com" className="btn brick-red-bg">Read More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a>
                  </div>
                </div>
              </div>
            </Col> */}

          <Col lg={4}>
            <div className="card box-shadow-02">
              <StaticImage
                src="../images/boycott.jpg"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                aspectRatio={1.5}
                className="card-img-top" />
              <div className="card-body">
                <h3 className="card-title">The Savannah Boycott</h3>
                <p className="card-text">Learn about the Savannah Boycott that broke the system of Jim Crow in Savannah.</p><i className="bi bi-arrow-right"></i>
                <div className="text-end">
                  <Link to="/educational-materials/savannah-boycott/" className="btn brick-red-bg">
                    Read More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
                  </Link>

                </div>

              </div>
            </div>
          </Col>
        </Row>
      </Container>



      {/* <StaticImage
              src="../images/civil-rights-museum-front-2.jpg"
              width={500}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            /> */}

      {/* <div className="neutral-gray-bg p-3 content-box">
             
             <Container className="my-3 my-md-5">
             
         
                 
                 <h2 className="mb-md-5 mb-3 display-5">Explore the Museum Virtually</h2>
                   
                   <div className="ratio ratio-21x9">
                   <iframe src="https://players.cupix.com/player.html?appid=reference&cmpmode=false&fpw=128&_v=1657814605753"  title="tour"></iframe>
                   </div>
             </Container>
             </div> */}

      <Container className="content-box">
        <h2 className=" display-5 mb-3">About The Museum</h2>
        <div className="brick-red-bg mb-3 mb-md-5">
          <Row>
            <Col lg={6} className="p-2 p-md-5 d-md-flex align-items-center align-self-center h-100">
              <Container>
                <h3 className="display-6">Historic Location</h3>

                <p>The museum is located in Savannah's Historic area, on Martin Luther King Jr. Boulevard. The street was once named West Broad Street, the heart of the city's black business community. The building built in 1914 as the Wage Earners Savings Bank was the second largest black bank in America at the time.
                </p>
              </Container>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../images/museum-exterior.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Museum Exterior" />
            </Col>
          </Row>
        </div>

        <div className="brick-red-bg ">
          <Row>
            <Col lg={6} className="p-2 p-md-5 d-md-flex align-items-center align-self-center h-100">
              <Container>
                <h3 className="display-6">Discover</h3>
                <p>Find on display three floors of informative historic photos, documentaries and interactive exhibits documenting the city's Jim Crow era and Civil Rights movement.</p>
              </Container>
            </Col>
            <Col lg={6}>
              <StaticImage
                src="../images/exhibit-space.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Exhibit Space" 
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="singleCol">
        <h2 className="text-center mb-3">We have postcards!</h2>
        <p>
          <StaticImage
            src="../images/two-postcards.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Exhibit Space" 
          />
        </p>
        <p>Take home one or both of our limited edition postcards: "The Meeting" & the Lunch Counter.</p>
        <hr />
      </Container>
      <Container className="singleCol my-2 my-md-5 content-box">
        <p className="float-img float-none float-md-start me-md-3 text-center">
          <StaticImage
            src="../images/eloria-gilbert.jpg"
            alt="Eloria Gilbert"
            placeholder="blurred"
            className="float-img" />
        </p>
        <h2>The Eloria S. Gilbert Archives at the Ralph Mark Gilbert Civil Rights Museum </h2>
        <p>
          The Archive was designed to receive the papers of the Reverend Dr. Ralph Mark Gilbert. The Gilbert Collection was donated to the Museum by Mrs. Eloria S. Gilbert and arrived, on site, on July 8, 2020. The Collection includes hundreds of type-written sermons Reverend Gilbert delivered as the 13th pastor of the First African Baptist Church, the noteworthy Passion Plays written and performed in Savannah and documents related to his work in Savannah and the state of Georgia, as president of the local and state chapters of the National Association of the Advancement of Colored People (NAACP).
        </p>
        <p>
          In the 1940s, while her husband, Reverend Ralph Mark Gilbert, headed up the state and local NAACP, in one part of Georgia, Mrs. Gilbert established others around the state. She also visited more than 25 US cities as an NAACP field representative, lecturer and orator. In the 1950s, Mrs. Gilbert was the  Postmistress at Savannah State Post Office, Thunderbolt, Georgia. After the death of Rev. Gilbert, in 1956, she maintained archive of his writings and in 2020 gave these documents to the Museum that bears his name. In the future we are expecting to add the papers of Eloria S. Gilbert to the Museum’s Collection.

        </p>
        <p>The Museum is honored to be named for Dr. Ralph Mark Gilbert and now as a tribute to Mrs. Gilbert, for her support and dedication to the Civil Rights Movement, there is now the Eloria S. Gilbert Archive at the Ralph Mark Gilbert Civil Rights Museum. </p>
        <p>
          The Ralph Mark Gilbert Civil Rights Museum accepts items to its Collection relative to the Savannah Civil Rights Movement. If you have any collectibles or know anyone who would like to help the Museum tell this story, please contact the Museum through this website or call 912-777-6099.
        </p>
        <div className="ratio ratio-16x9">
          <iframe src="https://player.vimeo.com/video/741977793?h=ff51ac1de7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="IMG_6119.mov"></iframe>
        </div>
      </Container>

      <div className="neutral-gray-bg p-3 content-box mt-5">



        <Container className="d-md-flex box-shadow-02 my-3 my-md-5">
          <div className="w-100">


            <h2 className="mb-md-5 mb-3 display-5">Plan Your Visit</h2>
            <h3>Location</h3>
            <p>460 Martin Luther King, Jr. Blvd., Savannah, Georgia 31401</p>
            <h3>We are Open</h3>
            <p>
              Thursday – Saturday<br />
              10am - 5pm (last tour at 3:30pm)</p>

            {/* <h3>Limited Group Tours Available</h3>
            <p>Tuesday & Wednesday to reserved groups of 20 or more. </p> */}

            <h3>Admission *</h3>

            <p>
              <strong>Individuals:</strong> Adults $14 | Seniors $8 | Students $7
              <br />
              <strong>Groups:</strong> Adults $10 | Seniors $7 | Students $5 | Educators with students $5
            </p>
            <p>
              * Individuals - Debit / Credit card only<br />
              * Groups - Debit / Credit / Business Checks
            </p>
          </div>
          <div className="ratio ratio-4x3">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.8944323875594!2d-81.10232294916513!3d32.07210392680649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb9e422d57c741%3A0x14f1d47e882ad43a!2sRalph%20Mark%20Gilbert%20Civil%20Rights%20Museum!5e0!3m2!1sen!2sus!4v1660317627584!5m2!1sen!2sus" title="map"></iframe>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="The History of Savannah's Civil Rights Movement" />

export default IndexPage

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "civil-rights-banner-wide.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          quality: 45
          blurredOptions: {toFormat: JPG, width: 100}
        )
      }
    }
    civilRightsHeroes: allMarkdownRemark(
      filter: {frontmatter: {featured: {eq: "yes"}}, fields: {slug: {glob: "/civil-rights-heroes/*"}}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            title
            shortDescription
            image01alt
            image01 {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  width: 550
                  blurredOptions: {width: 50, toFormat: JPG}
                  webpOptions: {quality: 30}
                  placeholder: BLURRED
                  quality: 60
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;